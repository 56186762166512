import { ArrowUpward, Search } from '@mui/icons-material';
import {
  Box,
  IconButton,
  Link,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  alpha,
} from '@mui/material';
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RemarketingVehicleSort } from '../../../../api/RemarketingService/common.js';

import { CSSTransition, TransitionGroup } from 'react-transition-group';
import {
  AuctionRemarketingVehicle,
  RemarketingType,
} from '../../../../api/util/RemarketingVehicle.js';
import { UserRemarketingVehicle } from '../../../../api/util/UserRemarketingVehicle.js';

import { useLocation } from 'react-router-dom';
import NoData from '../../../common-ui/components/NoData.js';
import { ErrorState } from '../../../common-ui/index.js';
import useCarouselMedia from '../../../hooks/useCarouselMedia.js';
import { useMobile } from '../../../hooks/useMobile.js';
import { shouldAnimateFieldChange } from '../../../util/remarketingVehicleStatusAnimation.js';
import { sx } from '../../../util/sx.js';
import { ListRemarketingAdminItemType } from '../../hooks/queries/useListRemarketingVehiclesForAdmin.js';
import ImageCarousel, { CarouselMedia } from '../VehicleMedia/ImageCarousel.js';
import AdminAuctionVehicleCard from './AdminAuctionVehicleCard.js';
import AdminUnassignedVehicleCard from './AdminUnassigendVehicleCard.js';
import AuctionVehicleCard from './AuctionVehicleCard.js';
import VehicleCardSkeleton from './VehicleCardSkeleton.js';

const styles = sx({
  searchIcon: {
    mr: 1,
    color: '#44546F',
    height: 22,
    width: 22,
  },
  progress: {
    mr: 1,
  },
  sortMenu: {
    minWidth: '300px',
  },
  item: {
    '@keyframes pulse': {
      from: {
        transform: 'scale3d(1, 1, 1)',
      },

      '50%': {
        transform: 'scale3d(1.02, 1.02, 1.02)',
      },

      to: {
        transform: 'scale3d(1, 1, 1)',
      },
    },
    '& .animated-enter': {
      transition: 'all 1s',
    },
    '& .animated-enter-active': {
      backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.3),
      border: (theme) => `2px solid ${theme.palette.primary.main}`,
      animation: 'pulse 1s',
    },
  },
});

export interface Sort {
  sort: RemarketingVehicleSort;
  order: 'asc' | 'desc';
}

export const DefaultSort: Sort = {
  order: 'asc',
  sort: RemarketingVehicleSort.END,
};

const sortTranslations: Record<RemarketingVehicleSort, string> = {
  [RemarketingVehicleSort.START]: 'pages.remarketingPage.sort.start',
  [RemarketingVehicleSort.END]: 'pages.remarketingPage.sort.end',
  [RemarketingVehicleSort.MODEL]: 'pages.remarketingPage.sort.model',
};

const ListItem = ({
  isAdminView,
  vehicle,
  onImageClick,
}: {
  isAdminView: boolean;
  vehicle: ListRemarketingAdminItemType | UserRemarketingVehicle;
  onImageClick?: () => void;
}) => {
  if (isAdminView && vehicle.type === RemarketingType.UNASSIGNED) {
    return (
      <AdminUnassignedVehicleCard
        key={vehicle.id}
        onImageClick={onImageClick}
        vehicle={vehicle}
      />
    );
  }
  return isAdminView ? (
    <AdminAuctionVehicleCard
      onImageClick={onImageClick}
      vehicle={vehicle as AuctionRemarketingVehicle}
    />
  ) : (
    <AuctionVehicleCard
      key={vehicle.id}
      onImageClick={onImageClick}
      vehicle={vehicle as UserRemarketingVehicle}
    />
  );
};

type RemarketingVehicleTableProps = {
  vehicles: (ListRemarketingAdminItemType | UserRemarketingVehicle)[];
  count: number;
  loading?: boolean;
  error?: unknown;
  onSearchTermChange: (searchTerm: string) => void;
  onSortChange: (sort: Sort) => void;
  isAdminView?: boolean;
  inputSearchValue?: string;
  sortOptions?: Sort;
};

export default function RemarketingVehicleTable({
  vehicles,
  loading,
  count,
  error,
  isAdminView,
  onSearchTermChange,
  onSortChange,
  inputSearchValue,
  sortOptions,
}: RemarketingVehicleTableProps) {
  const [searchValue, setSearchValue] = useState<string>('');
  const [sort, setSort] = useState<Sort>(DefaultSort);
  const { t } = useTranslation();
  const mobile = useMobile();
  const [carouselMedia, setCarouselMedia] = useState<CarouselMedia[]>([]);
  const { getFromVehicleMedia } = useCarouselMedia();
  const location = useLocation();
  const listRef = useRef<HTMLDivElement | null>(null);

  const resetFilters = () => {
    setSearchValue('');
    onSearchTermChange('');
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.target.value;
    setSearchValue(searchTerm);
    onSearchTermChange(searchTerm);
  };

  const handleSortChange = useCallback(
    (e: SelectChangeEvent<RemarketingVehicleSort>) => {
      setSort((prev) => {
        const newState: Sort = {
          ...prev,
          sort: e.target.value as RemarketingVehicleSort,
        };
        onSortChange(newState);

        return newState;
      });
    },
    [onSortChange],
  );

  const handleOrderChange = useCallback(() => {
    setSort((prev) => {
      const newState: Sort = {
        ...prev,
        order: prev.order === 'asc' ? 'desc' : 'asc',
      };
      onSortChange(newState);

      return newState;
    });
  }, [onSortChange]);

  useEffect(() => {
    setSort(sortOptions || DefaultSort);
  }, [sortOptions]);

  useEffect(() => {
    setSearchValue(inputSearchValue || '');
  }, [inputSearchValue]);

  useEffect(() => {
    if (location.state?.remarketingId && listRef.current) {
      const itemElement = document.getElementById(
        location.state?.remarketingId,
      );

      if (itemElement && listRef.current.contains(itemElement)) {
        setTimeout(() => {
          itemElement.scrollIntoView({ block: 'center', behavior: 'smooth' });
        }, 0);
      }
    }
  }, [location.state]);

  if (error) {
    return <ErrorState />;
  }

  return (
    <Stack spacing={2}>
      <>
        <Stack
          alignItems="center"
          direction={{
            xs: 'column',
            sm: 'row',
          }}
          justifyContent="space-between"
          spacing={2}
        >
          <TextField
            InputProps={{
              autoFocus: true,
              startAdornment: <Search sx={styles.searchIcon} />,
              endAdornment: searchValue && (
                <Box display="flex">
                  <Link
                    color="primary"
                    fontSize="small"
                    onClick={() => resetFilters()}
                    role="button"
                    underline="always"
                  >
                    {t('clear')}
                  </Link>
                </Box>
              ),
              autoComplete: 'off',
            }}
            fullWidth
            id="search-input"
            onChange={handleInputChange}
            placeholder={t('pages.remarketingPage.searchPlaceholder')}
            size="small"
            value={searchValue}
          />
          <Select
            fullWidth={mobile}
            onChange={handleSortChange}
            renderValue={(value) =>
              `${t('pages.remarketingPage.sort.title')}: ${t(
                sortTranslations[value],
              )}`
            }
            startAdornment={
              <IconButton
                onClick={handleOrderChange}
                sx={{
                  height: 20,
                  width: 20,
                  transform: `rotate(${sort.order === 'asc' ? 0 : 180}deg)`,
                  transition: 'transform 300ms',
                }}
              >
                <ArrowUpward />
              </IconButton>
            }
            sx={styles.sortMenu}
            value={sort.sort}
          >
            {Object.values(RemarketingVehicleSort).map((val) => {
              return (
                <MenuItem key={val} value={val}>
                  {t(sortTranslations[val])}
                </MenuItem>
              );
            })}
          </Select>
        </Stack>
        {loading ? (
          <VehicleCardSkeleton />
        ) : vehicles.length === 0 ? (
          <NoData text={t('noData')} />
        ) : (
          <div ref={listRef}>
            <Stack
              component={TransitionGroup}
              spacing={{ xs: 3, sm: 2 }}
              sx={styles.item}
            >
              {vehicles.map((v) => {
                const { updated, changes, id, status } =
                  v as ListRemarketingAdminItemType;
                const shouldRunUpdateAnimation =
                  changes?.includes('status') ||
                  (shouldAnimateFieldChange(status, changes) && !!updated);

                const key = shouldRunUpdateAnimation ? `${updated}-${id}` : id;

                return (
                  <div id={v.id} key={v.id}>
                    <CSSTransition
                      appear={false}
                      classNames="animated"
                      enter={shouldRunUpdateAnimation}
                      exit={false}
                      key={key}
                      timeout={1000}
                    >
                      <ListItem
                        isAdminView={!!isAdminView}
                        onImageClick={() => {
                          setCarouselMedia(getFromVehicleMedia(v.vehicleMedia));
                        }}
                        vehicle={v}
                      />
                    </CSSTransition>
                  </div>
                );
              })}
              <ImageCarousel
                media={carouselMedia}
                onClose={() => setCarouselMedia([])}
                open={!!carouselMedia.length}
              />
            </Stack>
          </div>
        )}
      </>
    </Stack>
  );
}
