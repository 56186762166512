import { array, object, string } from '@fmtk/decoders';

export interface FeedSyncStatusRequest {
  inboundFeedId: string;
  vins: string[];
}

export const decodeFeedSyncStatusRequest = object<FeedSyncStatusRequest>({
  inboundFeedId: string,
  vins: array(string),
});
