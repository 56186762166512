import { Stack } from '@mui/material';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { VehicleListing } from '../../../../api/util/Vehicle.js';
import NoData from '../../../common-ui/components/NoData.js';
import { ErrorState } from '../../../common-ui/index.js';
import VehicleCard from './VehicleCard.js';
import VehicleCardSkeleton from './VehicleCardSkeleton.js';

type VehicleTableProps = {
  vehicles: VehicleListing[];
  loading?: boolean;
  error?: unknown;
  archiveMode?: boolean;
};

export default function VehicleTable({
  vehicles,
  loading,
  error,
  archiveMode,
}: VehicleTableProps) {
  const { t } = useTranslation();
  const location = useLocation();
  const listRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (location.state?.vehicleId && listRef.current) {
      const itemElement = document.getElementById(location.state.vehicleId);

      if (itemElement && listRef.current.contains(itemElement)) {
        setTimeout(() => {
          itemElement.scrollIntoView({ block: 'center', behavior: 'smooth' });
        }, 0);
      }
    }
  }, [location.state]);

  if (error) {
    return <ErrorState />;
  }

  return (
    <Stack spacing={2}>
      {loading ? (
        <VehicleCardSkeleton />
      ) : (
        <>
          <Stack component="div" ref={listRef} spacing={{ xs: 3, sm: 2 }}>
            {vehicles.map((v: VehicleListing) => (
              <div id={v.id} key={v.id}>
                <VehicleCard archiveMode={archiveMode} vehicle={v} />
              </div>
            ))}
            {!loading && !vehicles.length && <NoData text={t('noData')} />}
          </Stack>
        </>
      )}
    </Stack>
  );
}
